import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

const AuthContext = createContext(null);

const authTokenKey = process.env.REACT_APP_AUTH_TOKEN_KEY || 'auth_token_default';
const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN_KEY || 'refresh_token_default';

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem(authTokenKey);
            const refreshToken = localStorage.getItem(refreshTokenKey);
            
            if (token && refreshToken) {
                try {
                    const response = await api.get('/auth/admin/me');
                    setUser(response.user);
                } catch (err) {
                    // If validation fails, tokens will be cleared by the interceptor
                    setUser(null);
                }
            }
            setLoading(false);
        };

        validateToken();
    }, []);

    const login = async (email, password) => {
        try {
            setError(null);
            const response = await api.post('/auth/admin/login', { email, password });
            
            const { access_token, refresh_token, user: userData } = response;
            
            localStorage.setItem(authTokenKey, access_token);
            localStorage.setItem(refreshTokenKey, refresh_token);
            
            setUser(userData);
            return { success: true };
        } catch (err) {
            setError(err.message);
            return { success: false, error: err.message };
        }
    };

    const logout = async () => {
        try {
            const refreshToken = localStorage.getItem(refreshTokenKey);
            if (refreshToken) {
                await api.post('/auth/logout', { refresh_token: refreshToken });
            }
        } catch (err) {
            console.error('Logout error:', err);
        } finally {
            localStorage.removeItem(authTokenKey);
            localStorage.removeItem(refreshTokenKey);
            setUser(null);
        }
    };

    const value = {
        user,
        loading,
        error,
        login,
        logout,
        setError,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 
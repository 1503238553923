import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Wallpapers from "./screens/Wallpapers";
import Banners from "./screens/Banners";
import BannerCreate from "./screens/BannerCreate";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Styles from "./screens/Styles";
import StyleCreate from "./screens/StyleCreate";

function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/">
                    {/* <Route path="sign-up" element={<SignUp />} /> */}
                    <Route path="sign-in" element={<SignIn />} />
                    
                    {/* Protected Routes */}
                    <Route
                        index
                        element={
                            <ProtectedRoute>
                                <Page title="Dashboard">
                                    <Home />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="wallpapers"
                        element={
                            <ProtectedRoute>
                                <Page title="Wallpapers">
                                    <Wallpapers />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="banners"
                        element={
                            <ProtectedRoute>
                                <Page title="Banners">
                                    <Banners />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="banners/create"
                        element={
                            <ProtectedRoute>
                                <Page title="Create banner">
                                    <BannerCreate />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="banners/:id"
                        element={
                            <ProtectedRoute>
                                <Page title="Banner">
                                    <BannerCreate />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/dashboard"
                        element={
                            <ProtectedRoute>
                                <Page title="Products dashboard">
                                    <ProductsDashboard />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/add"
                        element={
                            <ProtectedRoute>
                                <Page title="New product">
                                    <NewProduct />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/drafts"
                        element={
                            <ProtectedRoute>
                                <Page title="Drafts">
                                    <Drafts />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/released"
                        element={
                            <ProtectedRoute>
                                <Page title="Released">
                                    <Released />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/comments"
                        element={
                            <ProtectedRoute>
                                <Page title="Comments">
                                    <Comments />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/scheduled"
                        element={
                            <ProtectedRoute>
                                <Page title="Scheduled">
                                    <Scheduled />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/overview"
                        element={
                            <ProtectedRoute>
                                <Page title="Customers">
                                    <Customers />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/customer-list"
                        element={
                            <ProtectedRoute>
                                <Page title="Customer list">
                                    <CustomerList />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="shop"
                        element={
                            <ProtectedRoute>
                                <Page wide>
                                    <Shop />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="income/earning"
                        element={
                            <ProtectedRoute>
                                <Page title="Earning">
                                    <Earning />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="income/refunds"
                        element={
                            <ProtectedRoute>
                                <Page title="Refunds">
                                    <Refunds />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="income/payouts"
                        element={
                            <ProtectedRoute>
                                <Page title="Payouts">
                                    <Payouts />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="income/statements"
                        element={
                            <ProtectedRoute>
                                <Page title="Statements">
                                    <Statements />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="promote"
                        element={
                            <ProtectedRoute>
                                <Page title="Promote">
                                    <Promote />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="notification"
                        element={
                            <ProtectedRoute>
                                <Page title="Notification">
                                    <Notification />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="settings"
                        element={
                            <ProtectedRoute>
                                <Page title="Settings">
                                    <Settings />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="upgrade-to-pro"
                        element={
                            <ProtectedRoute>
                                <Page title="Upgrade to Pro">
                                    <UpgradeToPro />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="message-center"
                        element={
                            <ProtectedRoute>
                                <Page title="Message center">
                                    <MessageCenter />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="explore-creators"
                        element={
                            <ProtectedRoute>
                                <Page title="Explore creators">
                                    <ExploreCreators />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="affiliate-center"
                        element={
                            <ProtectedRoute>
                                <Page title="Affiliate center">
                                    <AffiliateCenter />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="styles"
                        element={
                            <ProtectedRoute>
                                <Page title="Styles">
                                    <Styles />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="styles/create"
                        element={
                            <ProtectedRoute>
                                <Page title="Create style">
                                    <StyleCreate />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="styles/:id"
                        element={
                            <ProtectedRoute>
                                <Page title="Style">
                                    <StyleCreate />
                                </Page>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="pagelist" element={<PageList />} />
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;

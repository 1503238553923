import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import ModalProduct from "../../../../../components/ModalProduct";

const Row = ({ item, value, onChange }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>
          <div
            className={styles.item}
          >
            <a
              href={item.preview_url}
              target="_blank"
              className={styles.preview}
            >
              <img
                srcSet={`${item.preview_url} 2x`}
                src={item.thumbnail_url}
                alt={item.title}
              />
            </a>
            <div className={styles.details}>
              <div className={styles.product}>{item.title}</div>
              <div className={styles.wrap}>
                <div className={styles.prompt}>
                  {item.prompt.length > 50
                    ? `${item.prompt.slice(0, 50)}...`
                    : item.prompt}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Style</div>
          <div className={styles.style}>
            {item.style ? item.style.display_name : "N/A"}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Premium</div>
          {item.is_premium ? (
            <div className={cn("status-green", styles.status)}>Yes</div>
          ) : (
            <div className={cn("status-red", styles.status)}>No</div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Private</div>
          <div className={styles.size}>
            {item.is_private ? (
              <div className={cn("status-green", styles.status)}>Yes</div>
            ) : (
              <div className={cn("status-red", styles.status)}>No</div>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Model</div>
          <div className={styles.model}>
            {item.generation_metadata
              ? item.generation_metadata.model_version
              : "N/A"}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Quality</div>
          <div className={styles.quality}>
            {item.generation_metadata
              ? item.generation_metadata.quality
              : "N/A"}
          </div>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;

import React, { useState, useEffect } from "react";
import TooltipGlodal from "../../components/TooltipGlodal";
import api from "../../services/api";
import Items from "../../components/Items";

const Banners = () => {
    const [bannersList, setBannersList] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
      { header: "Image", key: "image", imageKey: "background_image_url" },
      { header: "Title", key: "title" },
      { header: "Status", key: "status" },
      { header: "Banner Type", key: "banner_type" },
      { header: "Button Type", key: "button_action_type" },
      { header: "Order", key: "order" }
    ];

    const fetchBanners = async () => {
        try {
            setLoading(true);
            const data = await api.get('/banners/?page=1&limit=1000');
            setBannersList(data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchBanners();
    }, []);

    const handleDelete = () => {
        fetchBanners();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Items 
              items={bannersList} 
              onDelete={handleDelete}
              title="Banners"
              searchPlaceholder="Search banners"
              deleteEndpoint="/banners/delete-batch"
              deletePayloadKey="banner_ids"
              columns={columns}
              linkPrefix="banners"
            />
            <TooltipGlodal />
        </>
    );
};

export default Banners;

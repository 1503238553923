import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useAuth } from "../../contexts/AuthContext";

const SignIn = () => {
  const heightWindow = use100vh();
  const { login, error, setError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [loading, setLoading] = useState(false);
  
  // console REACT_APP_API_URL
  console.log('Environment Variables:', {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    NODE_ENV: process.env.NODE_ENV
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const { success, error } = await login(email, password);
      if (success) {
        navigate(from, { replace: true });
      } else {
        console.log({ error });
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-web.svg"
            srcDark="/images/logo-web.svg"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <div className={styles.body}>
          {error && (
            <div className={styles.error}>
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              disabled={loading}
            />
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
              disabled={loading}
            />
            <button 
              type="submit" 
              className={cn("button", styles.button)}
              disabled={loading}
            >
              {loading ? "Signing in..." : "Sign in"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

import React, { useState, useEffect } from "react";
import styles from "./Styles.module.scss";
import TooltipGlodal from "../../components/TooltipGlodal";
import api from "../../services/api";
import cn from "classnames";
import Card from "../../components/Card";
import TextInput from "../../components/TextInput";
import File from "../../components/File";
import Checkbox from "../../components/Checkbox";
import { useNavigate, useParams } from "react-router-dom";

const StyleCreate = () => {
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [promptTemplate, setPromptTemplate] = useState("");
    const [negativePrompt, setNegativePrompt] = useState("");
    const [exampleImage, setExampleImage] = useState(undefined);
    const [isActive, setIsActive] = useState(true);
    const [order, setOrder] = useState(0);
    const navigate = useNavigate();

    const { id } = useParams();
    const [style, setStyle] = useState(null);

    const fetchStyle = async () => {
        const data = await api.get(`/styles/${id}`);
        setStyle(data);
        setTitle(data.title || "");
        setDescription(data.description || "");
        setPromptTemplate(data.prompt_template || "");
        setNegativePrompt(data.negative_prompt || "");
        setExampleImage(data.background_image_url || "");
        setIsActive(data.is_active || true);
        setOrder(data.order || 0);
        setLoading(false);
    };

    useEffect(() => {
        if (id) {
            fetchStyle();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            const formData = new FormData();

            if (exampleImage && exampleImage !== style.background_image_url) {
                formData.append('background_image', exampleImage);
            }

            if (title !== style.title) formData.append('title', title);
            if (description !== style.description) formData.append('description', description);
            if (promptTemplate !== style.prompt_template) formData.append('prompt_template', promptTemplate);
            if (negativePrompt !== style.negative_prompt) formData.append('negative_prompt', negativePrompt);
            if (isActive !== style.is_active) formData.append('is_active', String(isActive));
            if (order !== style.order) formData.append('order', String(order));

            await api.put(`/styles/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        } else {
            const formData = new FormData();
            formData.append('background_image', exampleImage);
            formData.append('title', title);
            formData.append('description', description);
            formData.append('prompt_template', promptTemplate);
            formData.append('negative_prompt', negativePrompt);
            formData.append('is_active', String(isActive));
            formData.append('order', String(order));

            await api.post('/styles/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        }
        navigate("/styles");
    };

    const handleGenerate = async () => {
        const data = await api.put(`/styles/${id}/generate-image`);
        fetchStyle();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <form className={styles.row} onSubmit={handleSubmit}>
                <Card
                    title="Styles"
                    className={cn(styles.card)}
                    classTitle="title-green"
                >
                    <TextInput
                        className={styles.field}
                        label="Title"
                        name="title"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML allowed"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Description"
                        name="description"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML allowed"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Prompt Template"
                        name="promptTemplate"
                        type="text"
                        tooltip="Use {prompt} as placeholder for user input"
                        value={promptTemplate}
                        onChange={(e) => setPromptTemplate(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Negative Prompt"
                        name="negativePrompt"
                        type="text"
                        value={negativePrompt}
                        onChange={(e) => setNegativePrompt(e.target.value)}
                    />
                    <button className={cn("button", styles.button)} role="button" type="button" onClick={handleGenerate}>Generate</button>
                    <br />
                    <br />
                    <File
                        className={cn(styles.field, styles.file)}
                        title="Click or drop image"
                        label="Example image"
                        value={exampleImage}
                        onChange={(file) => setExampleImage(file)}
                    />
                    <Checkbox
                        name="active"
                        className={styles.field}
                        content="Active"
                        value={isActive}
                        onChange={(e) => setIsActive(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Order"
                        name="order"
                        type="number"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                    />
                    <button className={cn("button", styles.button)}>Save</button>
                </Card>
            </form>
            <TooltipGlodal />
        </>
    );
};

export default StyleCreate; 
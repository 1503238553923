import React, { useState, useEffect } from "react";
import TooltipGlodal from "../../components/TooltipGlodal";
import api from "../../services/api";
import Items from "../../components/Items";

const Styles = () => {
    const [stylesList, setStylesList] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
      { header: "Image", key: "image", imageKey: "background_image_url" },
      { header: "Title", key: "title" },
      { header: "Status", key: "status" },
      { header: "Prompt Template", key: "prompt_template" },
      { header: "Order", key: "order" }
    ];

    const fetchStyles = async () => {
        try {
            setLoading(true);
            const data = await api.get('/styles/?page=1&limit=1000');
            setStylesList(data);
        } catch (error) {
            console.error('Error fetching styles:', error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchStyles();
    }, []);

    const handleDelete = () => {
        fetchStyles();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Items 
              items={stylesList} 
              onDelete={handleDelete}
              title="Styles"
              searchPlaceholder="Search styles"
              deleteEndpoint="/styles/delete-batch"
              deletePayloadKey="style_ids"
              columns={columns}
              linkPrefix="styles"
            />
            <TooltipGlodal />
        </>
    );
};

export default Styles; 
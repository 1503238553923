import React, { useState, useEffect } from "react";
import styles from "./Wallpapers.module.scss";
import TooltipGlodal from "../../components/TooltipGlodal";
import api from "../../services/api";
import Products from "../ProductsDashboard/Products";

const Home = () => {
    const [wallpapers, setWallpapers] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchWallpapers = async () => {
        try {
            setLoading(true);
            const data = await api.get('/wallpapers/admin/library?page=1&limit=1000');
            setWallpapers(data);
        } catch (error) {
            console.error('Error fetching wallpapers:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {

        fetchWallpapers();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className={styles.row}>
                <Products items={wallpapers} onRestore={() => fetchWallpapers()} />
            </div>
            <TooltipGlodal />
        </>
    );
};

export default Home;

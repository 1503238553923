import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import ModalProduct from "../../../ModalProduct";
import { Link } from "react-router-dom";
import Control from "./Control";

const Row = ({ item, columns, value, onChange, showControls = true, onDelete, linkPrefix }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  const renderCell = (column, item) => {
    if (column.render) {
      return column.render(item);
    }

    if (column.key === 'image') {
      return (
        <Link to={`/${linkPrefix}/${item.id}`} className={styles.item}>
          <div className={styles.preview}>
            <img src={item[column.imageKey || 'image_url']} alt={item.title || 'Preview'} />
          </div>
        </Link>
      );
    }

    if (column.key === 'status') {
      return item.is_active ? (
        <div className={cn("status-green", styles.status)}>Yes</div>
      ) : (
        <div className={cn("status-red", styles.status)}>No</div>
      );
    }

    return item[column.key];
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        {columns.map((column, index) => (
          <div key={index} className={styles.col}>
            <div className={styles.label}>{column.header}</div>
            <div className={column.className || styles.content}>
              {renderCell(column, item)}
            </div>
          </div>
        ))}
        {showControls && (
          <div className={styles.col}>
            <Control 
              item={item} 
              linkPrefix={linkPrefix}
              onDelete={onDelete}
            />
          </div>
        )}
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;

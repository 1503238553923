import React from "react";
import cn from "classnames";
import styles from "./Balance.module.sass";
import Icon from "../Icon";

const Balance = ({ className, value, background }) => {
  return (
    <div
      className={cn(
        styles.positive,
        { [styles.background]: background },
        className
      )}
    >
      {value}
    </div>
  );
};

export default Balance;

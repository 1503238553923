import React from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../Icon";
import Actions from "../../../../Actions";
import { useNavigate } from "react-router-dom";

const Control = ({ className, item, linkPrefix = "items", onDelete }) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(`/${linkPrefix}/${item.id}`);
    };

    const handleDelete = () => {
        onDelete?.(item.id);
    };

    const actions = [
        {
            title: "Edit",
            icon: "edit",
            action: handleEdit,
        },
        {
            title: "Delete",
            icon: "trash",
            action: handleDelete,
        },
    ];

    return (
        <div className={cn(styles.control, className)}>
            <button className={styles.button} onClick={handleEdit}>
                <Icon name="edit" size="20" />
            </button>
            <button className={styles.button} onClick={handleDelete}>
                <Icon name="trash" size="20" />
            </button>
        </div>
    );
};

export default Control; 
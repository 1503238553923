import React, { useState } from "react";
import styles from "./Table.module.sass";
import Checkbox from "../../Checkbox";
import Icon from "../../Icon";
import Row from "./Row";

const Table = ({ items, onItemsSelect, columns = [], showControls = true, onDelete, linkPrefix }) => {
  const [chooseAll, setСhooseAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      const newFilters = selectedFilters.filter((x) => x !== id);
      setSelectedFilters(newFilters);
      onItemsSelect(newFilters);
    } else {
      const newFilters = [...selectedFilters, id];
      setSelectedFilters(newFilters);
      onItemsSelect(newFilters);
    }
  };

  const handleSelectAll = (value) => {
    const newFilters = value ? items.map((x) => x.id) : [];
    setSelectedFilters(newFilters);
    setСhooseAll(value);
    onItemsSelect(newFilters);
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={(event) => handleSelectAll(event.target.checked)}
            />
          </div>
          {columns.map((column, index) => (
            <div key={index} className={styles.col}>{column.header}</div>
          ))}
          {showControls && <div className={styles.col}>Actions</div>}
        </div>
        {items.map((item, index) => (
          <Row
            key={index}
            item={item}
            columns={columns}
            showControls={showControls}
            up={items.length - index <= 2}
            value={selectedFilters.includes(item.id)}
            onChange={() => handleChange(item.id)}
            onDelete={onDelete}
            linkPrefix={linkPrefix}
          />
        ))}
      </div>
      <div className={styles.foot}>
        <button className={styles.arrow}>
          <Icon name="arrow-left" size="20" />
        </button>
        <button className={styles.arrow}>
          <Icon name="arrow-right" size="20" />
        </button>
      </div>
    </div>
  );
};

export default Table;

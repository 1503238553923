import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const File = ({ className, label, tooltip, title, value, onChange }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    setPreview(value);
  }, [value]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      if (onChange) {
        onChange(file);
      }
    }
  };

  return (
    <div className={cn(styles.file, className)}>
      {label && (
        <div className={styles.label}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div className={cn(styles.wrap, { [styles.preview]: preview })}>
        <input 
          className={styles.input} 
          type="file" 
          onChange={handleChange}
          accept="image/*"
        />
        {preview ? (
          <div className={styles.preview}>
            <img src={preview} alt="Preview" />
          </div>
        ) : (
          <div className={styles.box}>
            <Icon name="upload" size="24" />
            {title}
          </div>
        )}
      </div>
    </div>
  );
};

export default File;

import React, { useState, useEffect } from "react";
import styles from "./Banners.module.scss";
import TooltipGlodal from "../../components/TooltipGlodal";
import api from "../../services/api";
import cn from "classnames";
import Card from "../../components/Card";
import TextInput from "../../components/TextInput";
import File from "../../components/File";
import Dropdown from "../../components/Dropdown";
import Checkbox from "../../components/Checkbox";
import { useNavigate, useParams } from "react-router-dom";

const BannerCreate = () => {
    const [loading, setLoading] = useState(true);
    const [bannerType, setBannerType] = useState("hero");
    const [buttonType, setButtonType] = useState("promo");
    const [backgroundImage, setBackgroundImage] = useState(undefined);
    const [isActive, setIsActive] = useState(true);
    const [order, setOrder] = useState(0);
    const [icon, setIcon] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [buttonActionData, setButtonActionData] = useState("");
    const navigate = useNavigate();

    const { id } = useParams();
    const [banner, setBanner] = useState(null);

    const fetchBanner = async () => {
        const data = await api.get(`/banners/${id}`);
        setBanner(data);
        setTitle(data.title || "");
        setDescription(data.description || "");
        setIcon(data.icon || "");
        setBackgroundImage(data.background_image_url || "");
        setButtonType(data.button_action_type || "");
        setButtonText(data.button_text || "");
        setButtonActionData(data.button_action_data || "");
        setIsActive(data.is_active || true);
        setOrder(data.order || 0);
        setBannerType(data.banner_type || "hero");
        setLoading(false);
    };

    useEffect(() => {
        console.log({id});
        if (id) {
            fetchBanner();
        } else {
            setLoading(false);
            // reset form
            setTitle("");
            setDescription("");
            setIcon("");
            setBackgroundImage(undefined);
            setButtonType("promo");
            setButtonText("");
            setButtonActionData("");
            setIsActive(true);
            setOrder(0);
            setBannerType("hero");
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            // update
            const formData = new FormData();

            if (backgroundImage && backgroundImage !== banner.background_image_url) {
                formData.append('background_image', backgroundImage);
            }

            if (icon && icon !== banner.icon) {
                formData.append('icon', icon);
            }

            if (title && title !== banner.title) {
                formData.append('title', title);
            }

            if (description && description !== banner.description) {
                formData.append('description', description);
            }

            if (buttonType && buttonType !== banner.button_action_type) {
                formData.append('button_action_type', buttonType);
            }

            if (buttonText && buttonText !== banner.button_text) {
                formData.append('button_text', buttonText);
            }

            if (buttonActionData && buttonActionData !== banner.button_action_data) {
                formData.append('button_action_data', buttonActionData);
            }

            if (isActive !== banner.is_active) {
                formData.append('is_active', String(isActive));
            }

            if (order !== banner.order) {
                formData.append('order', String(order));
            }

            if (bannerType && bannerType !== banner.banner_type) {
                formData.append('banner_type', bannerType);
            }

            const response = await api.patch(`/banners/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            navigate("/banners");
            return response;
        } else {
            try {
                // Create FormData instance
                const formData = new FormData();
                
                // Append the required background image
                formData.append('background_image', backgroundImage);
                
                // Append optional icon if provided
                if (icon) {
                    formData.append('icon', icon);
                }
                
                // Append other optional fields if they exist
                if (title) formData.append('title', title);
                if (description) formData.append('description', description);
                if (buttonText) formData.append('button_text', buttonText);
                if (buttonType) formData.append('button_action_type', buttonType);
                if (buttonActionData) formData.append('button_action_data', buttonActionData);
                
                // Append boolean and number values
                formData.append('is_active', String(isActive));
                formData.append('order', String(order));
                if (bannerType) formData.append('banner_type', bannerType);

                // Make the API call
                const response = await api.post('/banners/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
                navigate("/banners");
                return response;
            } catch (error) {
                console.error('Error creating banner:', error);
                throw error;
            }
        }
    };

    const handleBackgroundImageChange = (file) => {
        console.log({file});
        setBackgroundImage(file);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <form className={styles.row} onSubmit={handleSubmit}>
                <Card
                    title="Banners"
                    className={cn(styles.card)}
                    classTitle="title-green"
                >
                    <TextInput
                        className={styles.field}
                        label="Title"
                        name="title"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML allowed"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Description"
                        name="description"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML allowed"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Icon"
                        name="icon"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML allowed"
                        value={icon}
                        onChange={(e) => setIcon(e.target.value)}
                    />
                    <Dropdown
                        className={styles.field}
                        label="Banner type"
                        value={bannerType}
                        setValue={setBannerType}
                        options={["hero", "promo"]}
                    />
                    <File
                        className={cn(styles.field, styles.file)}
                        title="Click or drop image"
                        label="Banner image"
                        value={backgroundImage}
                        onChange={(file) => handleBackgroundImageChange(file)}
                    />
                    <Dropdown
                        className={styles.field}
                        label="Button type"
                        value={buttonType}
                        setValue={setButtonType}
                        options={["promo", "link", "collection", "style", "none"]}
                    />
                    <TextInput
                        className={styles.field}
                        label="Button text"
                        name="buttonText"
                        type="text"
                        tooltip="Maximum 100 characters. No HTML allowed"
                        value={buttonText}
                        onChange={(e) => setButtonText(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Button Action Data"
                        name="buttonActionData"
                        type="text"
                        value={buttonActionData}
                        onChange={(e) => setButtonActionData(e.target.value)}
                    />
                    <Checkbox
                        name="active"
                        className={styles.field}
                        content="Active"
                        value={isActive}
                        onChange={(e) => setIsActive(e.target.value)}
                    />
                    <TextInput
                        className={styles.field}
                        label="Order"
                        name="order"
                        type="number"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                    />
                    <button className={cn("button", styles.button)}>Save</button>
                </Card>
            </form>
            <TooltipGlodal />
        </>
    );
};

export default BannerCreate;

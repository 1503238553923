import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.wallmento.com/api/v1';

console.log('API URL:', apiUrl, 'NODE_ENV:', process.env.NODE_ENV, 'REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue = [];

const authTokenKey = process.env.REACT_APP_AUTH_TOKEN_KEY || 'auth_token_default';
const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN_KEY || 'refresh_token_default';

console.log('Auth Token Key:', authTokenKey);
console.log('Refresh Token Key:', refreshTokenKey);

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Request interceptor to add auth token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(authTokenKey);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors and token refresh
api.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;

    // If error is not 401 or request already retried, reject
    if (!error.response || error.response.status !== 401 || originalRequest._retry) {
      if (error.response) {
        const message = error.response.data?.detail || 'An error occurred';
        return Promise.reject({ message, status: error.response.status });
      } else if (error.request) {
        return Promise.reject({ message: 'No response from server', status: 503 });
      } else {
        return Promise.reject({ message: error.message, status: 500 });
      }
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return api(originalRequest);
        })
        .catch(err => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = localStorage.getItem(refreshTokenKey);
    
    if (!refreshToken) {
      processQueue(new Error('No refresh token'));
      return Promise.reject({ message: 'Session expired', status: 401 });
    }

    try {
      const response = await axios.post(`${apiUrl}/auth/refresh`, {
        refresh_token: refreshToken
      });

      const { access_token, refresh_token } = response.data;
      localStorage.setItem(authTokenKey, access_token);
      localStorage.setItem(refreshTokenKey, refresh_token);

      processQueue(null, access_token);
      originalRequest.headers['Authorization'] = `Bearer ${access_token}`;

      return api(originalRequest);
    } catch (err) {
      processQueue(err);
      localStorage.removeItem(authTokenKey);
      localStorage.removeItem(refreshTokenKey);
      return Promise.reject({ message: 'Session expired', status: 401 });
    } finally {
      isRefreshing = false;
    }
  }
);

export default api; 
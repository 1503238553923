import React, { useState } from "react";
import styles from "./Items.module.sass";
import cn from "classnames";
import Card from "../Card";
import Form from "../Form";
import Table from "./Table";
import api from "../../services/api";

const Items = ({ 
  items, 
  onDelete,
  title = "Items",
  searchPlaceholder = "Search items",
  deleteEndpoint,
  deletePayloadKey = "ids",
  columns = [],
  linkPrefix = "items"
}) => {
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleDelete = (items) => {
    api.post(deleteEndpoint, {
        [deletePayloadKey]: items
    }).then(() => {
      setSelectedItems([]);
      onDelete();
    });
  };

  const handleDeleteItem = (id) => {
    handleDelete([id]);
  };

  return (
    <Card
      className={styles.card}
      title={title}
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            placeholder={searchPlaceholder}
            type="text"
            name="search"
            icon="search"
          />
          {selectedItems.length > 0 && (
            <div className={styles.actions}>
              <button className={cn("button-stroke button-small", styles.button)} onClick={() => handleDelete(selectedItems)}>
                Delete
              </button>
              <button className={cn("button-stroke button-small", styles.button)}>
                Set status
              </button>
              <div className={styles.counter}>{selectedItems.length} selected</div>
            </div>
          )}
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          <Table
            items={items}
            onItemsSelect={setSelectedItems}
            columns={columns}
            onDelete={handleDeleteItem}
            linkPrefix={linkPrefix}
          />
        </div>
      </div>
    </Card>
  );
};

export default Items;
